.floorplanViewModalBox {
    animation: example 0.5s linear alternate;
    
}

@keyframes example{
    from{opacity: 0;}
    to{opacity: 100;}
}

.projectDetailsGalleryImg{
    height: 600px;
}

@media screen and (max-width:768px) {
    .projectDetailsGalleryImg{
        height: 400px;
    }
}
