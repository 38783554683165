// .allProjectsImg{
  
//     transition: 1s;
//     height:400px;
// }
// .allProjectsImg1{
//     transition: 1s;
//     height:400px;
// }
// .test{
//     transition: 5s;
// }
// .test:hover{
//     grid-column: span 4/span 4;
// }
// .test2:hover {
//     grid-column: span 8/span 8;
//     .test{
//         grid-column: span 4/span 4;
//     }
// }

.allProjectsSectionImg{
    width: 100%;
    height: 500px;
    
}

.inlinealign {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.allProjectsSectionAddressLine:before{
    content:" ";
    display: block;
    height: 3px;
    width: 130px;
    margin-top: -4px;
    margin-right: 4px;
    background: white;
}

.allProjectsSectionOpacityBox{
    opacity: 0;
    
}
.allProjectsSectionOpacityContainer:hover .allProjectsSectionOpacityBox{
    opacity: 100;
    transition: .2s all ease-in-out;

   
}