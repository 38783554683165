.heroSectionContainer{
   //  background: url('../../assets/mjbback.png');
   //  background-position:right;
   //  background-repeat: no-repeat;
   width: 100% !important;
    height: 80vh !important;
    margin: 0 auto;

    div {
      width: 100% !important;
      height: 100% !important;
      padding: 4px !important;
      box-sizing: border-box !important;
      img {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 100% !important;
         height: 100% !important;
         object-fit: cover;
         object-position: center;
      }
    }
   //  width: 100vw;
   //  div.react-multi-carousel-list {
   //    height: 100%;
   //    width: 100%;
      
   //     ul.react-multi-carousel-track {
   //       * {
   //          width: 100% !important;
   //          height: 100%;
   //        }
   //       width: 100%;
   //       height: 100%;
   //        li {
   //          height: 100%;
   //          width: 100%;
   //          div {
   //             height: 100%;
   //             width: 100%;
   //             img {
   //                width: auto !important;
   //                max-width: 100%;
   //                max-height: 100%;
   //                object-fit: contain;
   //                object-position: center;
   //             }
   //          }
   //        }
   //     }
   //  }
}

.heroSectionHeader{
   letter-spacing: 10px;
    
}

.heroSectionHeaderBox{
    margin-top: -270px;
    margin-left: -570px;
}

@media screen and (max-width:1500px) {
   .heroSectionHeader{
    font-size: 70px;
   }
   .heroSectionContainer{
    height: 80vh !important;
    margin-top: 20px;
   }
}
@media screen and (min-width:1024px) and (max-width:1180px) {
    .heroSectionHeader{
     font-size: 50px;
     margin-left: 50px;
     margin-top: 120px;
    }
    .heroSectionContainer{
   //   height: 60vh !important;
   //   margin-top: 20px;
   //   background-position: center;
   //   background-size: contain;
    }
 }
@media screen and (max-width:1024px) {
    .heroSectionHeader{
     font-size: 40px;
     margin-left: 50px;
     margin-top: 120px;
    }
   //  .heroSectionContainer{
   //   height: 60vh !important;
   //   margin-top: 20px;
   //   background-position: center;
   //   background-size: contain;
   //  }
 }
 @media screen and (max-width:910px) {
    .heroSectionHeader{
     font-size: 30px;
     margin-left: 150px;
     margin-top: 90px;
    }
   //  .heroSectionContainer{
   //   height: 55vh !important;
   //   margin-top: 20px;
   //   background-position: center;
   //   background-size: contain;
   //  }
 }
 @media screen and (max-width:768px) {
    .heroSectionHeader{
     font-size: 25px;
     margin-left: 170px;
     margin-top: 120px;
     letter-spacing: 5px;
    }
   //  .heroSectionContainer{
   //   height: 45vh !important;
   //   margin-top: 100px;
   //   background-position: center;
   //   background-size: contain;
   //  }
 }
 @media screen and (max-width:670px) {
    .heroSectionHeader{
     font-size: 20px;
     margin-left: 270px;
     margin-top: 120px;
     letter-spacing: 5px;
    }
   //  .heroSectionContainer{
   //   height: 45vh !important;
   //   margin-top: 100px;
   //   background-position: center;
   //   background-size: contain;
   //  }
 }

 @media screen and (max-width:540px) {
    .heroSectionHeader{
     font-size: 20px;
     margin-left: 270px;
     margin-top: 120px;
     letter-spacing: 5px;
    }
   //  .heroSectionContainer{
   //   height: 40vh !important;
   //   margin-top: 100px;
   //   background-position: center;
   //   background-size: contain;
   //  }
 }
 @media screen and (max-width:480px) {
    .heroSectionHeader{
     font-size: 18px;
     margin-left: 370px;
     margin-top: 170px;
     letter-spacing: 3px;
    }
   //  .heroSectionContainer{
   //   height: 35vh !important;
   //   margin-top: 20px;
   //   background-position: center;
   //   background-size: contain;
   //  }
 }